<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="3"
    viewBox="0 0 14 3"
    fill="none"
  >
    <rect y="0.428711" width="14" height="2" :fill="fillColor" />
  </svg>
</template>
<script>
  export default {
    props: {
      fillColor: {
        type: String,
        required: false,
        default: '#0384FB',
      },
    },
  };
</script>
