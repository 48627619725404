<template>
  <div ref="parentContainer" class="lg:px-auto w-full px-gap lg:container">
    <div class="-mx-gap flex flex-wrap md:-mx-gap2">
      <div
        class="wrapper--copy order-first w-12/12 px-gap pb-2 text-center md:pb-3"
      >
        <span v-if="modules.content" class="text-center lg:w-9/12">
          <RichTextRenderer
            :document="modules.content"
            :node-renderers="nodeRenderers"
          />
        </span>
      </div>
      <div
        ref="tabsContainerOuter"
        class="lg:pl-auto wrapper__tabs relative bg-white px-gap lg:ml-auto lg:mt-2"
        :class="[
          reversed
            ? 'wrapper__tabs--right lg:pr-gap2'
            : 'wrapper__tabs--left lg:pr-gap',
          tabsWrapperClasses,
        ]"
      >
        <div
          v-if="modules.tabs"
          ref="tabsContainerInner"
          class="wrapper__tabs--inner relative h-full w-full bg-white md:pl-3 md:pr-3 lg:absolute lg:pl-0 lg:pr-1"
        >
          <template v-for="(tab, index) in filteredTabs" :key="index">
            <ul
              v-if="Boolean(tab.fields.showTab) || tab.fields.showTab !== false"
              class="relative overflow-hidden border-b-1 border-gray-dark border-opacity-25"
            >
              <li
                class="wrapper__expand flex flex-col items-center justify-between overflow-hidden border-b-1 border-gray-dark border-opacity-25 pb-1 pt-1 hover:cursor-pointer"
                @click="setActiveTab(index)"
                @keydown="setActiveTab(index)"
              >
                <span class="flex w-full flex-row items-center justify-between">
                  <h5
                    class="cursor-pointer"
                    :class="
                      index === activeTab ? 'text-blue' : 'text-blue-dark'
                    "
                  >
                    {{ tab.fields.label }}
                  </h5>
                  <div class="w-[15px]">
                    <IconsSvgPlusIcon
                      v-if="index !== activeTab"
                      :fill-color="'#0384FB'"
                    />
                    <IconsSvgMinusIcon
                      v-else
                      :fill-color="'#0384FB'"
                      class="ml-auto"
                    />
                  </div>
                </span>
                <Transition name="fade" tag="div">
                  <div
                    v-show="index === activeTab"
                    :key="index"
                    class="wrapper__text pr-gap2"
                  >
                    <template v-if="tab.fields.tabContent">
                      <RichTextRenderer
                        :document="tab.fields.tabContent"
                        :node-renderers="nodeRenderers"
                      />
                    </template>
                    <div
                      v-for="(cta, i) in tab.fields.link"
                      :key="'cta' + i"
                      class="mb-1"
                    >
                      <ButtonsTheButton
                        v-if="cta.sys.contentType.sys.id === 'button'"
                        :button="cta.fields"
                      />
                      <LinksArrowLink
                        v-else-if="cta.sys.contentType.sys.id === 'arrowlink'"
                        :arrow-link="cta.fields"
                      />
                    </div>
                    <div
                      v-if="tab.fields.image.sys.contentType.sys.id === 'video'"
                      :ref="`video-${tab.fields.image.sys.id}`"
                      class="icon--video mt-space pb-1"
                    >
                      <button
                        class="button relative z-20 border-0 bg-transparent outline-none hover:cursor-pointer"
                        aria-live="assertive"
                        :aria-label="`${
                          tab['isMp4Playing'] ? 'pause' : 'play'
                        }`"
                        :aria-pressed="`${tab['isMp4Playing']}`"
                        @click.stop="toggleState"
                        @keydown.stop="toggleState"
                      >
                        <svg
                          v-show="tab['isMp4Playing']"
                          width="12"
                          height="16"
                          viewBox="0 0 12 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="button-icon--solid"
                        >
                          <rect width="4" height="16" fill="#00263E" />
                          <rect x="8" width="4" height="16" fill="#00263E" />
                        </svg>
                        <svg
                          v-show="!tab['isMp4Playing']"
                          width="14"
                          height="16"
                          viewBox="0 0 14 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="cursor-pointer"
                        >
                          <path d="M14 8L0 0V16L14 8Z" fill="#00263E" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </Transition>
              </li>
            </ul>
          </template>
        </div>
      </div>
      <div
        v-if="modules.tabs"
        class="lg:pb-auto relative order-first px-gap pb-2 lg:ml-auto"
        :class="[
          reversed ? '' : 'lg:order-last',
          mediaColumnClasses,
          mediaFlexClasses,
        ]"
      >
        <TransitionGroup
          name="fade"
          tag="div"
          class="wrapper--media relative z-10 block h-0 max-h-[518px] w-full overflow-hidden pb-[62%]"
        >
          <div
            v-for="(tab, index) in filteredTabs"
            v-show="index === activeTab"
            :key="'media' + index"
            class="absolute h-full w-full"
            :class="[
              reversed ? 'float-right lg:mr-gap' : 'lg:ml-gap',
              mediaClassObject(tab),
            ]"
          >
            <VideosTheVideo
              v-if="tab.fields.image.sys.contentType.sys.id === 'video'"
              :ref="tab.fields.image.sys.id"
              :mp4-player-state="tab['isMp4Playing'] ? 'play' : 'pause'"
              :video="tab.fields.image.fields"
              class="w-full"
            />
            <ImagesPicture
              v-if="tab.fields.image.sys.contentType.sys.id === 'image'"
              class="h-[517px]"
              :image="tab.fields.image.fields"
              :classes="'w-full z-1 absolute top-0 bottom-0 left-0 right-0'"
              :lazy="false"
            />
          </div>
        </TransitionGroup>
        <template v-if="modules.media">
          <ImagesPicture
            :image="modules.media[0].fields"
            class="absolute inset-0 h-full w-full"
          />
        </template>
      </div>
      <TabsDevAccordionTabsPopup
        v-if="modules.slides"
        :reversed="reversed"
        :content="modules.slides[0].fields"
      />
    </div>
  </div>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: {
      modules: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const { $emitter } = useNuxtApp();
      const videoLoaded = ref(false);
      const filteredTabs = ref([]);

      $emitter.on('video-loaded', () => {
        videoLoaded.value = true;
      });

      watch(videoLoaded, (newValue) => {
        videoLoaded.value = newValue;
      });

      const filtered = setTabs();
      filteredTabs.value = filtered;

      function setTabs() {
        let filteredTabs = [];
        if (props.modules.tabs && props.modules.tabs.length > 0) {
          filteredTabs = props.modules.tabs.filter((tab) => {
            if (checkMedia(tab.fields.image.sys.contentType.sys.id, tab)[0]) {
              if (
                tab.fields.label &&
                tab.fields.tabContent &&
                checkMedia(tab.fields.image.sys.contentType.sys.id, tab)[1]
              ) {
                if (tab.fields.image.sys.contentType.sys.id === 'video') {
                  tab.isMp4Playing = true;
                }
                return tab;
              }
            }
          });
        }
        return filteredTabs;
      }
      function checkMedia(type, tab) {
        switch (type) {
          case 'video':
            return [
              tab.fields.image.fields.video,
              tab.fields.image.fields.video.fields.file,
            ];
          case 'image':
            return [
              tab.fields.image.fields.image,
              tab.fields.image.fields.image.fields.file,
            ];
          default:
            return [
              tab.fields.image.fields.image,
              tab.fields.image.fields.image.fields.file,
            ];
        }
      }

      return {
        videoLoaded,
        filteredTabs,
      };
    },
    data() {
      return {
        statePath: 'M11,10 L17,10 17,26 11,26 M20,10 L26,10 26,26 20,26',
        activeTab: 0,
        reversed: !!(this.modules.json && this.modules.json.reverse === true),
      };
    },
    computed: {
      mediaColumnClasses() {
        const num_columns =
          this.modules.featuredColumnWidthDesktop === undefined ||
          this.modules.featuredColumnWidthDesktop === '9 columns'
            ? 9
            : this.modules.featuredColumnWidthDesktop.charAt(0);

        return `w-12/12 lg:w-${num_columns}/12`;
      },
      mediaFlexClasses() {
        return {
          'self-start':
            this.modules.featuredMediaHorizontalAlign === undefined ||
            this.modules.featuredMediaHorizontalAlign === 'Align Top',
          'self-center':
            this.modules.featuredMediaHorizontalAlign &&
            this.modules.featuredMediaHorizontalAlign === 'Align Center',
          'self-end':
            this.modules.featuredMediaHorizontalAlign &&
            this.modules.featuredMediaHorizontalAlign === 'Align Bottom',
        };
      },
      tabsWrapperClasses() {
        const num_columns =
          this.modules.tabsColumnWidthDesktop === undefined ||
          this.modules.tabsColumnWidthDesktop === '3 columns'
            ? 3
            : this.modules.tabsColumnWidthDesktop.charAt(0);

        return `w-12/12 lg:w-${num_columns}/12`;
      },
    },
    methods: {
      setActiveTab(index) {
        if ('isMp4Playing' in this.filteredTabs[this.activeTab]) {
          this.filteredTabs[this.activeTab].isMp4Playing = false;
        }
        this.activeTab = index;
        if ('isMp4Playing' in this.filteredTabs[this.activeTab]) {
          this.$nextTick(() => {
            this.filteredTabs[this.activeTab].isMp4Playing = true;
          });
        }
      },
      toggleState() {
        if ('isMp4Playing' in this.filteredTabs[this.activeTab]) {
          this.$nextTick(() => {
            this.filteredTabs[this.activeTab].isMp4Playing =
              !this.filteredTabs[this.activeTab].isMp4Playing;
          });
        }
      },
      dropShadow(tab) {
        return tab.fields.dropshadow === undefined ||
          tab.fields.dropshadow === true
          ? 'drop-shadow(0 6px 41px rgba(0,0,0,.3))'
          : '';
      },
      mediaClassObject(tab) {
        return {
          'float-right lg:mr-gap': this.reversed,
          'lg:ml-gap': !this.reversed,
          'bg-white':
            tab.fields.dropshadow === undefined ||
            tab.fields.dropshadow === true,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.3s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  button {
    &.button {
      &:focus {
        outline: none;
      }
      .icon {
        &--solid {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  .wrapper--media {
    :deep(.video) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    filter: drop-shadow(0 6px 41px rgba(0, 0, 0, 0.3));
  }
  .wrapper__tabs div:first-of-type .wrapper__expand {
    @screen md {
      @apply pt-2;
    }
  }
  .wrapper__expand {
    h5 {
      @apply mb-0 pb-0;
    }
  }
  .wrapper__tabs--left {
    border-top-left-radius: 25px;
    .wrapper__tabs--inner {
      border-top-left-radius: 25px;
    }
    &::before {
      content: '';
      @screen md {
        width: 40px;
        left: 100%;
        @apply absolute top-0 h-full bg-white;
      }
    }
  }
  .wrapper__tabs--right {
    border-top-right-radius: 25px;
    .wrapper__tabs--inner {
      border-top-right-radius: 25px;
    }
    &::before {
      content: '';
      @screen md {
        width: 40px;
        right: 100%;
        @apply absolute top-0 h-full bg-white;
      }
    }
  }
  .wrapper--copy {
    @screen lg {
      margin: 0 auto;
    }
    :deep(h5) {
      @apply text-blue;
    }
  }
  .wrapper__text {
    :deep(p) {
      margin-bottom: 15px;
    }
  }
  .icon--video {
    svg:hover path,
    svg:hover rect {
      fill: theme('colors.blue.DEFAULT');
    }
  }
</style>
